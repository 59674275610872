/*
  Jerica's Custom CSS Reset
  https://designselement.com/css/custom-css-reset/
*/

*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  margin: 0;
  line-height: inherit;
}
html {
  scrollbar-gutter: stable;
}
html:has([data-disable-document-scroll='true']) {
  overflow: hidden;
}
body {
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
  line-height: calc(0.95 + 0.62rem);
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
input,
button,
textarea,
select {
  font: inherit;
}

p,
h2,
h3,
h4,
h5,
h6 {
  text-wrap: pretty;
  overflow-wrap: break-word;
}

h1 {
  text-wrap: balance;
  overflow-wrap: break-word;
}

:root {
  /* HACK:
    Reach UI tests for loaded styles, but I'm providing my own.
    This is to avoid a noisy warning in dev.
  */
  --reach-dialog: 1;
}

/* ---------------------------------------- */
/* ---------- Blog custom styles ---------- */
/* ---------------------------------------- */

* {
  /* Cartograph comes with built-in ligatures for stuff like "=>". I don't want them. */
  -webkit-font-variant-ligatures: no-common-ligatures;
  font-variant-ligatures: no-common-ligatures;
}

/* Scrollbar and selection styles */
html[data-color-mode='light'] {
  --selection-background-color: hsl(50deg 100% 78%);
  --selection-text-color: black;
  --scrollbar-bg: var(--color-background);
  --scrollbar-thumb: var(--color-cloud-700);
  --scrollbar-thumb-hover: var(--color-gray-500);
}
html[data-color-mode='dark'] {
  --selection-background-color: hsl(250deg 20% 60% / 0.35);
  --selection-text-color: inherit;
  --scrollbar-bg: var(--color-background);
  --scrollbar-thumb: var(--color-gray-400);
  --scrollbar-thumb-hover: var(--color-gray-500);
}
::selection {
  background-color: var(--selection-background-color);
  color: var(--selection-text-color);
}

@media (min-device-width: 600px) {
  /*
    Ugh, so this property OVERRULES the webkit properties in Windows, causing the nice scrollbars to be replaced with standard square ones. They do inherit the correct colors, but none of the other styles.

    That said, without this property, Firefox gets no scrollbar colors at all. And maybe I should be respecting platform defaults.
  */
  * {
    scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-bg);
  }

  ::-webkit-scrollbar {
    width: 9px;
    height: 9px;
    background-color: var(--scrollbar-bg);
  }

  html::-webkit-scrollbar,
  body::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    background-color: var(--scrollbar-bg);
  }

  ::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: var(--scrollbar-bg);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--scrollbar-thumb);
    border: 2px solid var(--scrollbar-bg);
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-thumb-hover);
  }
}

html {
  color: var(--color-text);
  background-color: var(--color-background);
  transition:
    --color-background var(--color-swap-duration)
      var(--color-swap-timing-function),
    --color-text var(--color-swap-duration)
      var(--color-swap-timing-function);
}

body {
  --font-weight-bold: 600;
  --font-weight-medium: 500;
  --font-weight-light: 400;

  --font-family: 'Wotfard', 'Wotfard-fallback', sans-serif;
  --font-family-mono: 'Cartograph CF', monospace;
  --font-family-spicy: 'Sriracha', 'Wotfard-fallback', sans-serif;

  --viewport-padding: 32px;

  /*
    Normally for spring animations, I want to use React Spring.
    In certain cases, however, it's more performant to use native CSS transitions (eg. if there's a bunch of other DOM manipulation happening at the same time). In those cases, I can use these CSS variables to keep the springy feel:
  */
  --springy-spring-easing: linear(
    0,
    0.1407 4.43%,
    0.9383 16.72%,
    1.0774 20.43%,
    1.1493 24.31%,
    1.1629 26.95%,
    1.1536 29.93%,
    1.0168 43.09%,
    0.9766 51%,
    1.0033 76.88%,
    1
  );
  --springy-spring-duration: 833ms;

  font-family: var(--font-family);
}

/*
  We want to shrink the amount of space around the content on smaller screens.
  HACK: 35.1875rem is equivalent to the `sm` breakpoint in constants.ts. Ideally I would set this value in JS so that I could re-use that value, but I don't yet have a way to set global styles in JS in this project.
*/
@media (max-width: 35.1875rem) {
  body {
    --viewport-padding: 16px;
  }
}

button {
  /* UnstyledButton by default! */
  display: block;
  margin: 0;
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  text-align: left;
  font: inherit;
  color: inherit;
  outline-color: var(--color-primary);
}
a {
  outline-color: var(--color-primary);
}

kbd {
  display: inline-block;
  font-family: var(--font-family-mono);
  font-size: 0.9375em;
  padding: 0px 10px;
  margin: 1px -1px;
  border-bottom: 3px solid var(--kbd-border-color);
  border-radius: 3px 3px 5px 5px;
  background-color: var(--kbd-background-color);
  transition: var(--color-mode-transition);
  cursor: default;
}

/* Common animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadeToOpaque {
  to {
    opacity: 1;
  }
}
@keyframes fadeToTransparent {
  to {
    opacity: 0;
  }
}
@keyframes fadeFromTransparent {
  from {
    opacity: 0;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes slideFromHorizontal {
  from {
    transform: translateX(var(--slide-val, -100%));
  }
}
@keyframes slideToHorizontal {
  to {
    transform: translateX(var(--slide-val, 100%));
  }
}
@keyframes slideFromVertical {
  from {
    transform: translateY(var(--slide-val, -100%));
  }
}
@keyframes slideToVertical {
  to {
    transform: translateY(var(--slide-val, 100%));
  }
}

/*
  HACK: This should be in the `ModalSearch` component, but if I put it with the style definitions, it doesn't seem to work.
*/
@keyframes searchModalLeave {
  to {
    opacity: 0;
    transform: translateY(-50%);
  }
}
::view-transition-old(search-modal) {
  animation: searchModalLeave 500ms both;
  animation-delay: 250ms;
}
::view-transition-old(search-modal-content) {
  animation: fadeOut 340ms both;
}
::view-transition-old(search-modal-selected-result) {
  animation: fadeOut 600ms both;
  animation-delay: 150ms;
}

/* Remove the vertical translate for motion-intolerant folks */
@media (prefers-reduced-motion: reduce) {
  ::view-transition-old(search-modal) {
    animation: fadeOut 500ms both;
  }
}

@keyframes dismissNavDropdown {
  to {
    transform: translateY(-8px);
    opacity: 0;
  }
}
@keyframes navTipFlatten {
  /* HACK: A weird sub-pixel gap opens up without the 0.1px offset */
  from {
    transform: translateY(0.1px) scaleY(1);
    opacity: 1;
  }
  to {
    transform: translateY(-8.1px) scaleY(0.2);
    opacity: 0;
  }
}
::view-transition-old(navigation-dropdown) {
  animation: dismissNavDropdown 300ms;
}
::view-transition-old(navigation-tip) {
  animation: navTipFlatten 300ms;
  transform-origin: center bottom;
}

/* Fonts */
@font-face {
  font-family: 'Wotfard-fallback';
  src: local(Verdana);
  size-adjust: 90%;
  ascent-override: 93%;
  descent-override: 18%;
  line-gap-override: normal;
}

@font-face {
  font-family: 'Wotfard';
  src: url('/fonts/wotfard/wotfard-semibold-webfont.woff2')
    format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'Wotfard';
  src: url('/fonts/wotfard/wotfard-medium-webfont.woff2')
    format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'Wotfard';
  src: url('/fonts/wotfard/wotfard-regular-webfont.woff2')
    format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'Wotfard';
  src: url('/fonts/wotfard/wotfard-regular-italic-webfont.woff2')
    format('woff2');
  font-weight: 400;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'Cartograph CF';
  src: url('/fonts/cartograph-regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'Cartograph CF';
  src: url('/fonts/cartograph-regular-italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
  font-display: fallback;
}

.grecaptcha-badge {
  display: none !important;
}
